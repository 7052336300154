.login-style {
  display: table;
  width: 100%;
  height: 100vh;
  padding: 100px 0;
  color: white;
  background-color: #ffffff;
  background-position: 30% 45%;
  background-size: cover;
  .login-box {
    width: 250px;
    align-self: center;
    background-color: #004aad;
    height: 360px;
    .login-form{
      label{
          color: #ffffff;
      }
      .input-style{
          border-radius: 5px;
          border: 1px solid transparent;
      }
        .btn-style{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 36px;
          border-style: none;
          border-radius: 48px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          margin-top: 15px;
          font-family: Viga, sans-serif;
          font-style: normal;
          font-weight: lighter;
          font-size: 12px;
          cursor: pointer;
          margin-right: auto;
          margin-left: auto;
          margin-bottom: 15px;
          text-transform: uppercase;
          color: #ffffff;
          text-decoration: none;
          background-color: #70a6d6;
        }
    }
  }
}