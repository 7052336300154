.c_education{
    padding-bottom: 10px;
    .c_education_header{
        background-color: #006aff;
        // background-image: url('./../../../assets/background_header_pages.jpg');
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: white;
        font-weight: bold;
        span{
            font-size: 35px;
        }
    }
    .c_education_body{
        text-align: center;
        padding: 10px;
        color: #000000;
        font-family: "Roboto", Sans-serif;
        font-size: 17px;
        font-weight: 400;
        line-height: 2.8em;
        letter-spacing: 0.4px;
    }
    .c_education_button{
        display: flex;
        justify-content: center;
        align-items: center;
        button{
            height: 50px;
            border-color: transparent;
            border-radius: 35px;
            background-color: #70a6d6;
            color: black;
            padding: 0 25px;
        }
    }
    
    .c_education_frame{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        >.c_education_frame_title{
            padding: 10px 0;
            font-size: 20px;
            text-align: center;
            color: white;
        }
        iframe{
            width: 100%;
            height: 380px;
            padding: 0 10px 10px;
        }
    }
}

.fab-style{
    $fab-size: 64px;
    $fab-margin: 1 / 12 * 3 * $fab-size;
    right: $fab-margin;
}

/* $fab-color: #05ce0f;
$fab-size: 64px;
$fab-margin: 1 / 12 * 3 * $fab-size;
$fab-child-size: 1 / 3 * 2 * $fab-size;
$fab-child-margin: ($fab-size - $fab-child-size) / 2 + $fab-margin;

.fab{
	background: $fab-color;
	width: $fab-size;
	height: $fab-size;
	border-radius: 50%;
	text-align:center;
	color: #FFF;
	box-shadow:0px 0px 3px rgba(0,0,0,0.5),3px 3px 3px rgba(0,0,0,0.25);
	position: fixed;
	bottom: $fab-margin;
	right: $fab-margin;
	font-size: 2.6667em;
	display: inline-block;
	cursor: default;
}

.fab.child{
	right: $fab-child-margin;
	width: $fab-child-size;
	height: $fab-child-size;
	//display: none;
	
	font-size: 2em;
}

[sub-item="3"]{
    bottom: $fab-margin + 80; 
}

[sub-item="2"]{
    bottom: $fab-margin + 140; 
}

.fab span{
	vertical-align:middle;
}
 */