.c_header{
    background-color: #70a6d6;
    align-items: center;
    height: 60px;
  }
  .c_header_label{
    color: white;
    font-size: 22px;
  }
  .c_header_icon{
    zoom: 1.5;
    color: white;
  }